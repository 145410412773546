import React from 'react';
import Hawkers from 'assets/main/hawkers.jpeg';
import Youthopia from 'assets/main/youthopia.png';

import Section from 'components/Section';

const Media = () => (
  <Section>
    <main className="px-4 sm:px-6 lg:px-8">
      <h1 className="mx-auto mb-20 text-4xl tracking-tight font-bold text-gray-700 sm:text-3xl md:text-4xl text-center">
        <span className="block lg:inline">Featured Articles</span>
      </h1>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
        <div className="rounded overflow-hidden shadow-lg">
          <a
            href="https://youthopia.sg/read/kopirun-wants-to-become-singapores-everyday-dapao-app/"
            rel="nofollow noopener noreferrer"
            target="_blank">
            <img alt="Youthopia" className="object-cover h-48 w-full" src={Youthopia} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                Youthopia: KopiRun wants to become Singapore&#39;s everyday dapao app
              </div>
              <p className="text-gray-700 text-base">Read about the story of KopiRun.</p>
            </div>
            {/* <div className="px-6 pt-4 pb-2">
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hashtag1</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hashtag2</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hashtag3</span>
                            </div> */}
          </a>
        </div>
        <div className="rounded overflow-hidden shadow-lg">
          <a
            href="https://www.straitstimes.com/singapore/hawkers-worry-about-costs-of-hopping-on-to-online-delivery-platforms"
            rel="nofollow noopener noreferrer"
            target="_blank">
            <img alt="River" className="object-cover h-48 w-full" src={Hawkers} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                The Straits Times: Hawkers worry about costs of hopping on to online delivery
                platforms
              </div>
              <p className="text-gray-700 text-base" />
            </div>
          </a>
        </div>
      </div>
    </main>
  </Section>
);

export default Media;
